<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <b-button
            v-if="hasDeletedSubCompany"
            class="mb-4"
            variant="warning"
            @click="prepareDeletedLink()"
            >Silinmiş Firmalar</b-button
          >
          <b-button
            class="mb-4"
            variant="primary"
            @click="navigateToAddNewCompany"
          >
            {{ $t(addButtonText) }}
          </b-button>
        </div>

        <CompanyDatePickers
          :filters="filters"
          @option-selected="onOptionSelected"
          @on-start-date-selected="onStartDateSelected"
          @on-end-date-selected="onEndDateSelected"
          @on-all-dates-selected="onAllDatesSelected"
        />

        <TableFilter @filter-change="onFilterChange" />

        <b-table
          ref="listTable"
          id="companies-table"
          primary-key="_id"
          :items="fetchData"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterIncludedFields"
          selectable
          select-mode="single"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @row-selected="onRowSelected"
          @refreshed="onRefreshed"
          :responsive="true"
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>

          <!-- <template #cell(hasQuickSale)="data">
            <b-icon
              icon="check"
              font-scale="2.5"
              class="common-icon-color"
              variant="success"
              v-if="data.value"
            ></b-icon>
            <b-icon
              icon="x"
              v-else
              font-scale="2.5"
              class="common-icon-color"
            ></b-icon>
          </template> -->

          <template #cell(isActive)="data">
            <b-icon
              icon="check"
              font-scale="2.5"
              class="common-icon-color"
              variant="success"
              v-if="data.value"
            ></b-icon>
            <b-icon
              icon="x"
              v-else
              font-scale="2.5"
              class="common-icon-color"
            ></b-icon>
          </template>

          <template #cell(buttons)="row">
            <button
              class="edit-button mr-2"
              @click="prepareUsersLink(row.item)"
            >
              <b-icon icon="person" variant="info" font-scale="1.5"></b-icon>
            </button>
            <button
              class="edit-button mr-2"
              @click="navigateToWallet(row.item)"
              :title="$t('ROUTES.WALLET')"
            >
              <!-- <b-icon icon="wallet2" variant="info" font-scale="1.3"></b-icon> -->
              <i class="la la-wallet text-info" style="scale: 1.3;"></i>
            </button>
            <button
              class="edit-button mr-2"
              @click="navigateToEditCompany(row.item)"
            >
              <b-icon icon="pencil" variant="primary" font-scale="1.3"></b-icon>
            </button>

            <button
              v-if="showDetailButton"
              class="edit-button"
              @click="fetchSubData(row)"
            >
              <b-icon
                :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
                variant="success"
                font-scale="1.5"
              ></b-icon>
            </button>
          </template>

          <template #row-details="row">
            <!-- <b-card v-if="isMerchant" class="mb-4">
              <b-row>
                <b-col sm="4" offset="2">
                  <h6 class="m-0">
                    <span class="font-weight-normal text-secondary">
                      {{
                        $t(
                          "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.ALL_ORDERS"
                        )
                      }}:
                    </span>
                    <span>{{ row.item.ordersCount }}</span>
                  </h6>
                </b-col>
                <b-col sm="6">
                  <h6 class="m-0">
                    <span class="font-weight-normal text-secondary">
                      {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_TOTAL") }}:
                    </span>
                    <span>{{ row.item.ordersTotal }}</span>
                  </h6>
                </b-col>
              </b-row>
            </b-card>

            <b-card v-else class="px-2 mb-4" no-body>
              <b-table
                :caption="$t('ROUTES.BRANCHES')"
                caption-top
                ref="branchTable"
                primary-key="id"
                :items="row.item.branches"
                :fields="branchFields"
              ></b-table>
            </b-card> -->
            <b-card v-if="isDistributor" class="px-2 mb-4" no-body>
              <b-table
                :caption="$t('ROUTES.BRANCHES')"
                caption-top
                ref="branchTable"
                primary-key="id"
                :items="row.item.branches"
                :fields="branchFields"
              >
                <template #cell(buttons)="row">
                  <button
                    class="edit-button"
                    @click="navigateToEditBranch(row.item)"
                  >
                    <b-icon
                      icon="pencil"
                      variant="primary"
                      font-scale="1.3"
                    ></b-icon>
                  </button>
                </template>
              </b-table>
            </b-card>
          </template>
        </b-table>

        <div class="mt-5 col-12 pt-5">
          <b-pagination
            align="fill"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="orders-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions, mapGetters } from "vuex";
import TableFilter from "@/components/list/TableFilter.vue";
import CompanyDatePickers from "@/components/list/CompanyDatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";

export default {
  components: { TableFilter, CompanyDatePickers },
  mixins: [ListMixin],
  data() {
    return {
      hasDeletedSubCompany: 0,
      filterIncludedFields: [
        "createdBy.firstName"
        // "title",
        // "createdBy.lastName",
      ],
      filters: [
        /* {
          text: "COMPANY.QUICK_SALE",
          field: "hasQuickSale",
          currentValue: undefined
        }, */
        {
          text: "COMMON.ACCESS",
          field: "isActive",
          currentValue: undefined
        }
      ],
      fields: [
        // {
        //   key: "_id",
        //   label: "ID",
        //   sortable: true,
        // },
        {
          key: "title",
          label: this.$t("COMPANY.TITLE"),
          sortable: true
        },
        /* {
          key: "representativeName",
          label: this.$t("COMPANY.SALES_REPRESENTATIVE"),
          sortable: true
        }, */
        /* {
          key: "hasQuickSale",
          label: this.$t("COMPANY.QUICK_SALE"),
          sortable: true,
          class: "text-center"
        }, */
        {
          key: "isActive",
          label: this.$t("COMMON.ACCESS"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "createdAt",
          label: this.$t("LIST.CREATED_AT"),
          sortable: true,
          class: "text-center",
          formatter: value => {
            // return this.$moment(value).format("DD.MM.YYYY, HH:mm");
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "buttons",
          label: "",
          class: "text-center"
        }
      ],
      branchFields: [
        {
          key: "title",
          label: this.$t("COMPANY.TITLE"),
          sortable: true
        },
        {
          key: "email",
          label: this.$t("FORM.EMAIL"),
          sortable: true
        },
        {
          key: "createdAt",
          label: this.$t("LIST.CREATED_AT"),
          sortable: true,
          class: "text-center",
          formatter: value => {
            // return this.$moment(value).format("DD.MM.YYYY, HH:mm");
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "buttons",
          label: "",
          class: "text-center"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isMerchant: "isMerchant",
      isDistributor: "isDistributor",
      mainCompanyId: "orders/MAIN_COMPANY_ID"
    }),
    showDetailButton() {
      // return this.isMerchant || this.isDistributor;
      return this.isDistributor;
    },
    addButtonText() {
      return this.isMerchant ? "COMPANY.NEW_BRANCH" : "COMPANY.NEW_MERCHANT";
    },
    fetchUrl() {
      return `companies/${this.mainCompanyId}/companies`;
    }
  },
  mounted() {
    const endRoute = this.isMerchant ? "ROUTES.BRANCHES" : "ROUTES.COMPANIES";

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t(endRoute) }
    ]);
  },
  methods: {
    ...mapActions({
      getSubcompanies: "company/GET_SUB_COMPANIES"
    }),
    async fetchSubData(row) {
      const detailsShowing = !row.detailsShowing;
      const item = row.item;

      const action = this.isMerchant
        ? this.fetchOrdersTotals
        : this.fetchBranches;

      await action(detailsShowing, item);

      row.toggleDetails();
      detailsShowing ? row.selectRow() : row.unselectRow();
    },
    async fetchBranches(detailsShowing, item) {
      const hasAnyBranch = item.branches && item.branches.length;
      const notFetched = !hasAnyBranch ?? true;

      if (detailsShowing && notFetched) {
        const subCompanies = await this.getSubcompanies(item._id);
        item["branches"] = subCompanies;
      }
    },
    async fetchOrdersTotals(detailsShowing, item) {
      const notFetched = isNaN(item.ordersCount);

      if (detailsShowing && notFetched) {
        const ordersTotals = await this.$store.dispatch(
          "orders/GET_ORDERS_TOTAL",
          item._id
        );

        item["ordersTotal"] = ordersTotals.ordersTotal;
        item["ordersCount"] = ordersTotals.ordersCount;
      }
    },
    navigateToAddNewCompany() {
      this.$router.push({ name: "company-companies-company" });
    },
    async navigateToEditCompany(company) {
      this.$router.push({
        name: "company-companies-company-edit",
        params: { companyId: company._id }
      });
    },
    prepareUsersLink(company) {
      this.$store.commit("company/CLEAR_SELECTED_COMPANY");
      this.$router.push({
        name: "company-companies-company-users",
        params: { companyId: company._id }
      });
    },
    prepareDeletedLink() {
      this.$router.push({ name: "company-companies-deleted" });
    },
    navigateToWallet(company) {
      this.$router.push({
        name: "company-wallet",
        params: { companyId: company._id }
      });
    },
    navigateToEditBranch(branch) {
      this.$router.push({
        name: "edit-branch",
        params: { branchId: branch.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  margin: 0;
  background-color: transparent;
  border: none;
}

.table td {
  padding: 0.5rem;
  vertical-align: middle;
}
</style>
