<template>
  <div class="row align-items-center mb-5">
    <div class="col-12 col-md-4">
      <label></label>
      <b-form-datepicker
        name="startCalendar"
        v-model="startDate"
        selected-variant="success"
        today-variant="info"
        nav-button-variant="primary"
        hide-header
        :locale="locale"
        :min="minDate"
        :max="maxDate"
        :disabled="datePickersDisabled"
        :placeholder="$t('PAGES.SALES_MANAGEMENT.LIST.SELECT_START_DATE')"
        @input="$emit('on-start-date-selected', startDate)"
      ></b-form-datepicker>
    </div>
    <div class="col-12 col-md-4">
      <label></label>
      <b-form-datepicker
        name="endCalendar"
        v-model="endDate"
        selected-variant="success"
        today-variant="info"
        nav-button-variant="primary"
        hide-header
        :locale="locale"
        :min="minDate"
        :max="maxDate"
        :disabled="datePickersDisabled"
        :placeholder="$t('PAGES.SALES_MANAGEMENT.LIST.SELECT_END_DATE')"
        @input="$emit('on-end-date-selected', endDate)"
      ></b-form-datepicker>
    </div>
    <div class="col-auto">
      <label></label>
      <b-form-checkbox
        v-model="allDates"
        value="true"
        unchecked-value="false"
        @input="onChecked"
      >
        {{ $t("PAGES.SALES_MANAGEMENT.LIST.ALL_DATES") }}
      </b-form-checkbox>
    </div>

    <div class="col-auto mr-auto">
      <span
        v-for="(filter, filterIndex) in filters"
        :key="filterIndex"
        :class="[
          'd-inline-flex flex-column align-items-center',
          { 'ml-4': filterIndex > 0 }
        ]"
      >
        <label>{{ $t(filter.text) }}</label>
        <b-dropdown
          size="sm"
          :text="getButtonText(filterIndex)"
          variant="outline-primary"
        >
          <b-dropdown-item-button
            @click="$emit('option-selected', filterIndex, option.value)"
            v-for="(option, optionIndex) in options"
            :key="optionIndex"
          >
            <span class="d-inline-flex align-items-center">
              <b-icon
                v-if="isOptionActive(filterIndex, option.value)"
                icon="check"
                font-scale="1.5"
                class="mr-1"
                variant="success"
              ></b-icon>
              {{ $t(option.text) }}
            </span>
          </b-dropdown-item-button>
        </b-dropdown>
      </span>
    </div>
  </div>
</template>

<script>
import i18nService from "@/services/i18n.js";

export default {
  name: "DatePickers",
  props: ["filters"],
  data() {
    return {
      minDate: new Date("2021-01-01"),
      maxDate: new Date(),
      startDate: null,
      endDate: null,
      allDates: true,
      datePickersDisabled: true,
      filter: null,
      selectedFilters: [],
      locale: i18nService.getActiveLanguage(),
      options: [
        {
          text: "COMMON.access_granted",
          value: 1
        },
        {
          text: "COMMON.access_restricted",
          value: 0
        },
        {
          text: "ECOMMERCE.COMMON.ALL",
          value: undefined
        }
      ]
    };
  },
  methods: {
    onChecked(checked) {
      const isChecked = checked === "true";
      this.datePickersDisabled = isChecked;
      if (isChecked) {
        this.startDate = null;
        this.endDate = null;
      }
      this.$emit("on-all-dates-selected", checked);
    },
    getButtonText(filterIndex) {
      const currentValue = this.filters[filterIndex].currentValue;
      const optionItem = this.options.filter(
        option => option.value == currentValue
      );
      return this.$t(optionItem[0].text);
    },
    isOptionActive(filterIndex, value) {
      return this.filters[filterIndex].currentValue === value;
    }
  }
};
</script>
